import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import bookingLuminor from '../../static/booking/reservation-luminor.pdf';
import bookingUrsulines from '../../static/booking/reservation-ursulines.pdf';
import pdfArchipel from '../../static/booking/Cinelangues-Bon-de-reservation-Archipel.pdf';

import archipel from '../images/cinemas/ARCHIPEL.jpg';
import caumartins from '../images/cinemas/logo-lesCaumartins.jpg';
import parnassiens from '../images/cinemas/logo-lesparnassiens.jpg';
import ursulines from '../images/cinemas/Studio_des_Ursulines.jpg';
import luminor from '../images/cinemas/luminor.png';
import lincoln from '../images/cinemas/LOGO_LINCOLN.png';
import dulac from '../images/cinemas/Dulac-Cinemas.jpeg';
import cineastes from '../images/cinemas/logo-cinema-cineastes.png';

const BookingPage = () => (
  <Layout>
    <SEO
      title="Comment réserver ? Cinélangues"
      description="Cinélangues poursuit, depuis de nombreuses années maintenant, toujours avec autant de passion et de conviction, son action de diffusion des cinématographies."
      pathname="/a-propos-reserver/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 col-md-8 header-content ml-2">
            <h1>
              <i className="fal fa-ticket ml-2 mr-2"></i>Comment réserver ?
            </h1>
          </div>
        </div>
      </div>
      <div className="container content reserver">
        <div className="row">
          <div className="card card-orange col-lg-6">
            <div className="card-body">
              <h3 className="mt-0">
                Pour organiser une séance scolaire avec vos élèves, prendre contact directement par mail ou téléphone avec un de nos cinémas partenaires. Vous trouverez ci-après les coordonnées des Responsables Jeune Public ou Médiateurs culturels des cinémas qui seront vos interlocuteurs privilégiés dans votre démarche.
              </h3>
            </div>
          </div>
          <div className="card col-lg-6 card-illustration-1 d-none d-lg-block"></div>
        </div>
        <div className="row">
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body pb-2">
              <div className="row">
                <div className="col-6 offset-3">
                  <img src={luminor} className="w-100" alt="Luminor" />
                </div>
                <div className="col-lg-12">
                  <h3 className="mb-0 d-none">Luminor Hotel de Ville</h3>
                  <p className="mb-1 text-muted">20, rue du Temple, 75004 Paris</p>
                  <small>
                    <a
                      href="http://www.luminor-hoteldeville.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.luminor-hoteldeville.com
                    </a>
                  </small>
                </div>
              </div>
              <p className="card-text mt-2">
                Les séances scolaires ont lieu généralement en matinée (sauf pour les
                films en sortie nationale qui peuvent avoir lieu l’après-midi). En
                fonction du nombre d’élèves, elles peuvent parfois être programmées à
                14h. 4 euros par élève (gratuit pour les accompagnateurs)
              </p>
              <p className="card-text">
                <strong>Jennifer de Castro</strong>
                <br />
                <a href="tel:0142779388">01 42 77 93 88</a>
                <a href="mailto:cinelangues@luminor-hoteldeville.com">
                  cinelangues@luminor-hoteldeville.com
                </a>
                <a
                  className="btn btn-warning"
                  href={bookingLuminor}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Télécharger le bon de réservation
                </a>
              </p>
            </div>
          </div>
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body">
              <div className="row">
                <div className="col-4 offset-4">
                  <img src={ursulines} className="w-100" alt="Ursulines" />
                </div>
                <div className="col-lg-12">
                  <h3 className="mb-0 d-none">Le Studio des Ursulines</h3>
                  <p className="mb-1 text-muted">
                    10, rue des Ursulines / 75005 Paris
                    <br />
                    M. Luxembourg - Censier Daubenton
                  </p>
                  <small>
                    <a
                      href="http://www.studiodesursulines.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.studiodesursulines.com
                    </a>
                  </small>
                </div>
              </div>
              <p className="card-text mt-2">
                Les séances scolaires ont lieu en matinée (9h/9H30) ou l'après-midi
                (13h30/14h).
              </p>
              <p className="card-text">
                <strong>Anne-Sophie Cabaret, médiatrice culturelle</strong>
                <br />
                <a href="mailto:annesophie@studiodesursulines.com">
                  annesophie@studiodesursulines.com
                </a>
                <a
                  className="btn btn-warning"
                  href={bookingUrsulines}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Télécharger le bon de réservation
                </a>
              </p>
            </div>
          </div>
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body">
              <div className="row">
                <div className="col-6 offset-3">
                  <img src={parnassiens} className="w-100" alt="Parnassiens" />
                </div>
                <div className="col-lg-12">
                  <h3 className="mb-0 mt-1 d-none">Cinéma Les 7 Parnassiens</h3>
                  <p className="mb-1 text-muted">
                    98, Boulevard du Montparnasse, 75017 Paris
                    <br />
                    M. Vavin
                  </p>
                  <small>
                    <a
                      href="https://www.parnassiens.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.parnassiens.com
                    </a>
                  </small>
                </div>
              </div>
              <p className="card-text mt-2">
                Séances scolaires en matinée. 4 euros par élève (gratuit pour les
                accompagnateurs)
              </p>
              <p className="card-text">
                <strong>Anais Coenca</strong>
                <br />
                Responsable Jeune Public et Solidarités
                <br />
                <a href="tel:0688282290">06.88.28.22.90</a>
                <a href="mailto:anais.coenca@multicine.fr">anais.coenca@multicine.fr</a>
              </p>
            </div>
          </div>
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body">
              <div className="row">
                <div className="col-6 offset-3">
                  <img src={caumartins} className="w-100" alt="Caumartins" />
                </div>
                <div className="col-lg-12">
                  <h3 className="d-none mb-0 mt-1">Cinéma Les 5 Caumartin</h3>
                  <p className="mb-1 text-muted">
                    101, rue St Lazare, 75009 Paris
                    <br />
                    M. St Lazare - Havre Caumartin
                  </p>
                  <small>
                    <a
                      href="https://www.cinqcaumartin.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.cinqcaumartin.com
                    </a>
                  </small>
                </div>
              </div>
              <p className="card-text mt-2">
                Séances scolaires en matinée. 4 euros par élève (gratuit pour les
                accompagnateurs)
              </p>
              <p className="card-text">
                <strong>Anais Coenca</strong>
                <br />
                Responsable Jeune Public et Solidarités
                <br />
                <a href="tel:0688282290">06.88.28.22.90</a>
                <a href="mailto:anais.coenca@multicine.fr">anais.coenca@multicine.fr</a>
              </p>
            </div>
          </div>
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body">
              <div className="row">
                <div className="col-6 offset-3">
                  <img src={archipel} className="w-100" alt="Archipel" />
                </div>
                <div className="col-lg-12">
                  <h3 className="mb-0 mt-1 d-none">Cinéma L’Archipel</h3>
                  <p className="mb-1 text-muted">
                    17 boulevard de Strasbourg, 75010 Paris
                    <br />
                    M. Strasbourg Saint-Denis, Château d’eau
                  </p>
                  <small>
                    <a
                      href="https://www.larchipel.net/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.larchipel.net
                    </a>
                  </small>
                </div>
              </div>
              <p className="card-text mt-2">
                Séances scolaires en matinée (9h30 ou 10h en fonction de la durée du
                film et des possibilités de la salle) pour un nombre minimum de 60
                élèves ou séances scolaires l’après-midi pour un nombre minimum de 30
                élèves.
              </p>
              <p className="card-text">
                <a href="tel:0173547980">01 73 54 79 80</a>
                <a href="mailto:cinema@larchipel.net">cinema@larchipel.net</a>
                <a href={pdfArchipel}>Télécharger le bon de réservation</a>
              </p>
              <p className="card-text">
                <strong>Emmanuelle Lacalm</strong>
                <br />
                <a href="mailto:e.lacalm@larchipel.net">e.lacalm@larchipel.net</a>
              </p>
            </div>
          </div>
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body">
              <div className="row">
                <div className="col-8 offset-2">
                  <img src={lincoln} className="w-100" alt="Archipel" />
                </div>
                <div className="col-lg-12">
                  <h3 className="mb-0 mt-1 d-none">Cinéma Le Lincoln</h3>
                  <p className="mb-1 text-muted">14 Rue Lincoln, 75008 Paris</p>
                  <small>
                    <a
                      href="https://www.lelincoln.com/le-cine/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.lelincoln.com
                    </a>
                  </small>
                </div>
              </div>
              <p className="card-text mt-2">
                Séances scolaires en matinée. 4 euros par élève (gratuit pour les
                accompagnateurs)
              </p>
              <p className="card-text">
                <strong>Anais Coenca</strong>
                <br />
                Responsable Jeune Public et Solidarités
                <br />
                <a href="tel:0688282290">06.88.28.22.90</a>
                <a href="mailto:anais.coenca@multicine.fr">anais.coenca@multicine.fr</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4 text-center">
            <div className="card-body">
              <div className="col-6 offset-3">
                <img src={cineastes} className="w-100" alt="Cineastes" />
              </div>
              <p>
                7, Avenue de Clichy 75017 Paris, M° Place Clichy
                <br />
                <br />
                Séances scolaires en matinée. 4 euros par élève (gratuit pour les
                accompagnateurs)
                <br />
                Contact: <strong>Sophie Fangain</strong>
              </p>
              <p>
                <a href="mailto:sfangain@larp.fr">sfangain@larp.fr</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4 text-center">
            <div className="card-body">
              <div className="col-6 offset-3">
                <img src={dulac} className="w-100" alt="Cineastes" />
              </div>
              <h3>L'Arlequin</h3>
              <p>
                76, rue de Rennes 75006 Paris, M° St Sulpice <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4 text-center">
            <div className="card-body">
              <div className="col-6 offset-3">
                <img src={dulac} className="w-100" alt="Cineastes" />
              </div>
              <h3>Le Reflet Médicis</h3>
              <p>
                3, rue Champollion 75005 Paris, M° St Michel
                <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4 text-center">
            <div className="card-body">
              <div className="col-6 offset-3">
                <img src={dulac} className="w-100" alt="Cineastes" />
              </div>
              <h3>L'Escurial</h3>
              <p>
                11, Bd du Port Royal 75013 Paris, M° Censier Daubenton
                <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4 text-center">
            <div className="card-body">
              <div className="col-6 offset-3">
                <img src={dulac} className="w-100" alt="Cineastes" />
              </div>
              <h3>Le Majestic Bastille</h3>
              <p>
                18, rue de Passy 75016 Paris, M° Passy
                <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4 text-center">
            <div className="card-body">
              <div className="col-6 offset-3">
                <img src={dulac} className="w-100" alt="Cineastes" />
              </div>
              <h3>Le Majestic Passy</h3>
              <p>
                4, Bd Richard Lenoir 75011 Paris M° Bastille
                <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default BookingPage;
